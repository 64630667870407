import React, { useState } from 'react'
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from "@reach/router"
import Modal from "react-bootstrap/Modal"
// import $ from 'jquery'
import SendFriendForm from '@Components/forms/sendfrnd-form';
import { Share } from '@Components/icon/icon';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
}
    from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share"

const SocialShare = (props) => {
	    const [Shareicons, setShareicons] = React.useState(false);
	    const location = useLocation();
    	const shareUrl = location.href
		const openShareicons = () => {
            setShareicons(!Shareicons)
	    }
	  const [show, setShow] = useState(false);

	  const handleClose = () => setShow(false);
      const handleShow = (event) => {   
        debugger
        setShow(true);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });   
    }
      const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }



	    return (props.jobDetails ? 
            
            <div className="share-fet">
                                                        {props.isMob ? <a onClick={openShareicons} className="icons">
                                             <i className="icon-share">

                                             </i>
                                        </a> :
            <a onClick={openShareicons}  href="javascript:void(0)" className="like">
                                            <i className="icon-share">
                                            </i>
                                            <span className="sm-text">
                                                share
                                        </span>
                                        </a>}
            {/* <a onClick={openShareicons}  href="javascript:void(0)" className="btn-share share">
                  <Share /> <span>{props.Title}</span> */}
                  {Shareicons &&
        <div className="hover-share">
            <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareUrl} className="my-share-button facebook-share">
                <FacebookIcon size={32} round={false} borderRadius={`10`} />
            </FacebookShareButton>
            <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareUrl} className="my-share-button twitter-share">
                <TwitterIcon size={32} round={false} borderRadius={`10`} />
            </TwitterShareButton>
            <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareUrl} className="my-share-button linked-share">
                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
            </LinkedinShareButton>

            <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareUrl} className="my-share-button whatsapp-share">
                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
            </WhatsappShareButton>
            <EmailShareButton onClick={()=>handleShow('SendToFriend')} className="my-share-button email-share">
                <EmailIcon size={32} round={false} borderRadius={`10`} />
            </EmailShareButton>
        </div>
    }
		
          <Modal
              id="share_modal_mobile"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
       		 dialogClassName="modal-90w modal-form"
            >
              <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h6 className="text-center content-primary-color mb-0 text-uppercase">Share to a Friend</h6></Modal.Title>
              </Modal.Header>
              <Modal.Body>
			  <SendFriendForm job_url={props.job_url}  />

              </Modal.Body>
            </Modal>      
            </div>
            :
	    	<>
        <div onClick={openShareicons}  href="javascript:void(0)" className="icon-social">
                                            <i className="icon-share">
                                            </i>

                                        <span className="sm-text d-none d-md-flex">
                                            Share
                                    </span>
                                    </div>
                                    {/* {Shareicons && <> */}
                                    <div className="icon-social">
                                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareUrl}>
                                            <i className="icon-twitter">

                                            </i>
                                        </TwitterShareButton>
                                    </div>
                                    <div className="icon-social">
                                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareUrl}>
                                            <i className="icon-fb">

                                            </i>
                                        </FacebookShareButton>
                                    </div>
                                    <div className="icon-social">
                                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareUrl}>
                                            <i className="icon-linkedln">

                                            </i>
                                        </LinkedinShareButton>
                                    </div>
                                    {/* </>} */}
                                    {/* <div className="icon-social">
                                        <Link >
                                            <i className="icon-file">

                                            </i>
                                        </Link>
                                    </div> */}
		
        </>
)

}
export default SocialShare